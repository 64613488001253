html {
  background-color: #FFFFFF;
  box-sizing: border-box;
}

body, p, li{
  font-family:  'Roboto', sans-serif;
}
h1, h2, h3{
  font-family: 'Rajdhani', sans-serif;
}


*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}
:root {
  --text-primary-color: #3E3E3E;
  --heаding-primary-color: #454545;
  --bg-primary-color: #E3F3F9;
  --link-primary-color: #3577D9;
  --button-primary-color: #FFE55B;
  --paragrapgh-color: #F7F7F7;
  --li-color: #303141;
  --li-circle-color: #FFE55B;
  --bg-box: #F7F7F7;
}

body {
  font-size: 16px;
  line-height: 30px;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  min-width: 320px;
  margin: 0px;
}

h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  color: var(--heаding-primary-color);
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  text-transform: uppercase;
  color: var(--heаding-primary-color);
  padding: 35px 0 10px;
}
h3{
  font-style: normal;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
}
p{
  font-weight: 300;
  font-size: 17px;
  line-height: 35px;  
  color: var(--text-primary-color);
  padding: 20px 0;
}
p+p{
  padding-top: 0px;
}

a {
  text-decoration: none;
  color: var(--link-primary-color);
}
ul{
  list-style: none;
}
@media only screen and (max-width: 1067px) {
  h1{
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    margin-top: 30px;
  }
  h2{
    font-size: 30px;
    line-height: 30px;
    text-align: center;
  }
  p{
    font-size: 16px;
    line-height: 30px;
    text-align: center;
  }
}
/* General */

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1149px;
}
@media (min-width: 1400px){
  .container {
      min-width: 1350px;
  }
}
@media only screen and (max-width: 1025px) {
  .container {
      padding: 0 20px;
  }
}
.grid {
  display: grid;
  grid-gap: 20px;
  gap: 20px;
}
.col2-1{
  grid-template-columns: 2fr 1fr;
}
.col2{
  grid-template-columns: 1fr 1fr;
}
.col3{
  grid-template-columns: 2fr 2fr 2fr;
}
.text-white{
  color: white;
}
.text-center{
  text-align: center;
}
.bg-grey{
  background-color: var(--bg-box);
}
.block{
  padding: 50px 0;
}
.slide-down {
  height: 0;
  overflow: hidden;
  transition: height 0.2s linear;
}
.m-slide-down-measure p {
  padding-top: 20px;
  margin: 0 !important;
}
.m-visible {
  display: none;
}
.m-hidden {
  display: initial;
}
@media only screen and (max-width: 1067px){
  .container {
    padding-left: 25px!important;
    padding-right: 25px!important;
}
  .main{
    margin-bottom: 20px;
  }
  .col2, .col2-1, .col3{
    grid-template-columns: 1fr;
  }
  .site-header::after, .site-header .container::before, .site-header::before{
    content: none!important;
  }
  .mobile-detector, .site-header .main-image {
      display: none;
  }
  .site-header .title{
    bottom: 0!important;
    padding-right: 0!important;
  }
  .site-header .intro{
    padding: 0!important;
  }

  .m-hidden {
      display: none;
  }

  .m-visible {
      display: initial;
  }

  .m-slide-down {
      -moz-transition: height .5s;
      -ms-transition: height .5s;
      -o-transition: height .5s;
      -webkit-transition: height .5s;
      transition: height .5s;
      height: 0;
      overflow: hidden;
  }
}


/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  margin-bottom: 25px;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .banner-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  background-color: transparent;
  padding: 0;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .banner-title{
      font-size: 22px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
      text-align: left!important;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .banner-title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center!important;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

/* Header */

.scroll-to-link > *{
  pointer-events: none;
}
.site-header {
  width: 100%;
  padding: 0 0 70px;
  position: relative;
}
.site-header .header{
  align-items: center;
}
.site-header .site-logo {
  position: relative;
  padding: 20px 0;
}
.site-header .site-logo .main-logo, .footer .main-logo{
  display: inline-block;
  font-size: 0;
  height: 27px;
  position: relative;
  vertical-align: middle;
  width: 188px;
}
.site-header .site-logo .main-logo{
  background: url(../image/logo.svg) no-repeat 0 0/contain;
}
.site-header .site-logo::after{
  background: #E5E5E5;
  bottom: 0;
  content: "";
  height: 2px;
  left: 0;
  position: absolute;
  width: 18%;
}
.site-header .container{
  z-index: 1;
  position: relative;
}
.site-header .container .hero::before{
  content: "";
  position: absolute;
  top: 15%;
  left: 15%;
  height: 44px;
  width: 42px;
  background: url(../image/ball-bg.webp) no-repeat;
  background-size: cover;
  display: block;
}
.site-header .intro-primary.grid{
  justify-items: center;
  align-items: baseline;
  position: relative;
}
@-moz-document url-prefix() {
  .site-header .intro-primary.grid{
    display: flex;
  }
  .site-header .title, .site-header .site-nav, .site-header .main-image{
    width: 33.33%;
  }
  @media only screen and (max-width: 1067px) {
    .site-header .intro-primary.grid{
      display: grid;
    }
    .site-header .title, .site-header .site-nav, .site-header .main-image{
      width: 100%;
    }
  }
}
.site-header .title{
  z-index: 1;
  position: relative;
  bottom: 100px;
  padding-right: 40px;
}
.site-header .intro{
  padding: 0 150px;
}
.site-header .main-image{
  position: relative;
  height: 450px;
}
.site-header::before{
  content: "";
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: var(--li-circle-color);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 240px;
  margin-left: auto;
  margin-right: auto;
}
.site-header::after{
  content: "";
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: auto;
  background-image: url(../image/hero.webp);
  width: -webkit-fill-available;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 240px;
}
.double-arrow {
  animation: arrow-move .5s linear infinite;
  background-image: url(../image/arrow_down.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 22px;
  height: 22px;
  margin: 0 auto;
  display: block;
  margin-top: 40px;
}

@keyframes arrow-move {
  0% {transform: translateY(0px)}
  50% {transform: translateY(-5px)}
  100% {transform: translateY(0px)}
}
@media only screen and (max-width: 1067px) {
  .site-header{
      padding: 0;
  }
  .site-header .container{
      padding: 0;
  }
  .site-header .site-logo .main-logo{
    width: 150px;
  }
  .site-header .site-logo::after{
    content: none;
  }
  .site-header .intro-primary.grid{
    position: unset;
  }
  .site-header .intro-entry{
      grid-template-columns: 1fr;
      padding: 0px 20px 40px 20px;
  }
  .main-image{
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      grid-row: 1;
  }
  .site-header .main-image::before{
      width: 60%;
  }
  .site-header .main-image::after{
     content: none;
  }
  .site-header .intro-entry p{
      width: 100%;
  }
  .double-arrow{
    margin-bottom: 40px;
  }
}
/* Navigation */

.site-nav{
  position: relative;
  bottom: 115px;
  padding-left: 40px;
}
.btn-group li{
  margin-bottom: 40px;
  margin-right: 20px;
}
.btn-group li:nth-of-type(2){
  margin-left: 20px;
}
.btn-group li:before {
  content: "\003E";
  font-size: 13px;
  font-weight: 300;
  color: var(--li-color);
  background: var(--button-primary-color);
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: inline-flex;
  align-content: flex-end;
  justify-content: center;
  align-items: center;
  margin-left: -20px;
  position: relative;
  top: -3px;
  left: -10px;
  transition: 0.2s;
}
.btn{
  font-family: Rajdhani;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  line-height: 30px;
  color: var(--li-color);
}
.btn-group li:hover .btn{
  color: #3577D9;
}
.btn-group li:hover:before{
  color: white;
  background: #3577D9;
}
  @media (min-width: 1300px){
      a.button {
          min-width: 170px;
      }
  }
  @media only screen and (max-width: 1067px){
      .site-header .site-nav{
          z-index: auto;
          position: unset;
      }
      .nav-visible .site-logo{
          padding-top: 10px;
      }
      .site-nav .icon{
          border-top: 3.5px solid var(--button-primary-color);
          height: 20px;
          width: 30px;
          box-sizing: border-box;
          position: absolute;
          z-index: 30;
          right: 20px;
          top: 20px;
          cursor: pointer;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .site-nav .icon:before {
          content: "";
          display: block;
          position: absolute;
          height: 3.5px;
          width: 30px;
          right: 0;
          background: var(--button-primary-color);
          top: 5px;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .site-nav .icon:after {
          content: "";
          display: block;
          position: absolute;
          height: 3.5px;
          width: 30px;
          right: 0;
          background: var(--button-primary-color);
          bottom: -1px;
          -webkit-transition: all 0.3s ease-in;
          -moz-transition: all 0.3s ease-in;
          -khtml-transition: all 0.3s ease-in;
          -o-transition: all 0.3s ease-in;
          transition: all 0.3s ease-in;
      }
      .nav-visible .site-nav .icon{
          border: transparent;
      }
      .nav-visible .site-nav .icon:before {
          -webkit-transform: rotate(45deg);
          -moz-transform: rotate(45deg);
          -khtml-transform: rotate(45deg);
          -o-transform: rotate(45deg);
          transform: rotate(45deg);
          width: 33px;
          right: -2px;
      }
      .nav-visible .site-nav .icon:after {
          -webkit-transform: rotate(135deg);
          -moz-transform: rotate(135deg);
          -khtml-transform: rotate(135deg);
          -o-transform: rotate(135deg);
          transform: rotate(135deg);
          width: 33px;
          right: -1px;
          top: 6px;
      }
      .nav-visible .site-nav{
          position: fixed;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          bottom: 0;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: left;
          z-index: 5;
          background: #303141;
      }
      .site-header.nav-visible .container{
          display: flow-root;
      }
      .site-nav>ul{
          opacity: 0;
          visibility: hidden;
          display: none;
      }
      .nav-visible .site-nav>ul {
          display: grid;
          justify-content: center;
          align-items: center;
          opacity: 1;
          visibility: visible;
          position: fixed;
          width: 70%;
          height: 50%;
          top: 70px;
          left: 0;
          right: 0;
          bottom: 0;
          overflow-x: hidden;
          overflow-y: auto;
          text-align: center;
          margin: 0 auto;
      }
      .nav-visible .site-nav>ul>li{
        margin-bottom: 0px; 
        margin-right: 0px;
        padding: 15px;
      }
      .nav-visible .site-nav>ul>li:hover{
        background: #4f505e;
        border-radius: 22px;
      }
      .nav-visible .site-nav>ul>li:hover a{
        color: white;
      }
      .nav-visible .site-nav>ul>li:nth-of-type(2) {
        margin-left: 0px;
    }
      .nav-visible .site-nav>ul>li:before{
        content: none;
      }
      .nav-visible .site-nav>ul>li a{
          font-size: 23px;
          line-height: 30px;
          text-align: center;
          color: #FFFFFF;
      }

      .nav-visible  .btn-group{
          box-shadow: none;
      }
      .nav-visible .site-nav>ul>li a.btn ,  .nav-visible .btn-group li:not(:last-child):not(.dropdown-toggle) .btn, .nav-visible .btn-group li:not(:first-child) .btn{
          border: none;
          background-color: transparent;
      }
}

/* Buttons */

.ctc-btn .button{
  float: right;
}
.button{
  font-size: 19px;
  line-height: 20px;
  color: #FFFFFF;
  font-weight: normal;
  padding: 14px 43px;
  transition-duration: .2s;
  position: relative;
  border: none;
}
.primary.button{
  background: #303141;
}
.primary.button:hover{
  background: #4A4C6B;
}
.secondary.button{
  background: var(--button-primary-color);
  color: #202022;
  font-weight: bold;
  font-size: 22px;
}
.secondary.button:hover{
  background: #FFDA15;
}
/*
.button:hover:before{
  background: #EEEFF8;
  border-radius: 69px;
  bottom: 0;
  color: #4a4a4a;
  content: "Services under development";
  font-weight: 300;
  height: inherit;
  left: -40px;
  opacity: .95;
  position: absolute;
  transform: translateY(-60px);
  width: max-content;
  padding: 10px 10px;
}
.secondary.button:hover:before{
  font-size: 20px;
}
.button:hover:after {
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 8px solid #EEEFF8;
  clear: both;
  content: "";
  height: 0;
  left: 43%;
  opacity: .95;
  position: absolute;
  top: 4px;
  transform: translateY(-16px);
  width: 0;
}
*/
@media only screen and (max-width: 1067px){
  .site-header .ctc-btn{
    display: none;
  }
  .ctc-btn .button {
    float: none;
  }
}

/* Lines */

.top-lines{
  position: relative;
}
.top-lines::before{
  content: "";
  background: var(--button-primary-color);
  top: 0;
  height: 64px;
  left: 0;
  position: absolute;
  width: 40%
}
.top-lines::after{
  content: "";
  background: var(--li-color);
  top: 0;
  height: 64px;
  right: 0;
  position: absolute;
  width: 59%
}
.top-lines h2{
  padding-top: 130px;
}
@media only screen and (max-width: 1067px){
  .top-lines::before{
    width: 31%
  }
  .top-lines::after{
    width: 67%
  }
}
/* Table */

table.vertical-table{
  width: 100%;
  border: 0;
  border-collapse: separate;
  border-spacing: 15px;
  font-weight: normal;
  font-size: 17px;
  margin: 0 -15px;
}
table.vertical-table th, table.vertical-table td{
  border-style: solid none;
  border-width: 1px;
  padding: 10px 20px;
  position: relative;
  text-align: left;
  border-color: transparent;
  min-width: 290px;
  height: 82px;
  overflow: hidden;
}

table.vertical-table th{
  background-color: var(--li-circle-color);
  text-transform: uppercase;
  font-size: 20px;
}
table.vertical-table td {
  background-color: var(--li-color);
  color: white;
}
@media only screen and (max-width: 1067px){
    table.vertical-table{
      border-spacing: unset;
      margin: 0
    }
    table.vertical-table tr{
      display: grid;
      gap: 13px;
      padding-bottom: 13px;
    }
    table.vertical-table td, table.vertical-table th{
      text-align: center;
      height: auto
    }
}

/* Фоотер */
.main-footer{
  background: #202022;
  padding-top: 50px;
}
.main-footer .container{
  align-items: end;
    border-bottom: 1px solid #39393C;
    padding-bottom: 50px;
}
.main-footer p{
  padding-bottom: 0;
}
.bottom-footer{
  background: #202022;
  padding: 20px 0;
  text-align: center;
  color: #707278;
}
@media only screen and (max-width: 1067px){
  .main-footer .container{
    justify-items: center;
  }
}

